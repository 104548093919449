import React from "react"
import { navigate } from "gatsby"
import logo from "./../../images/logo.png"

import traveler from "./../../images/traveler.png"
import designer from "./../../images/designer.png"
import Image from "../image"
export const SignUpPicker = () => {
  const displayRightImage = type => {
    console.log({ type })
    if (type === "designer")
      return (
        <Image src="designer.png" alt="traveler" style={{ width: "100%" }} />
      )
    if (type === "traveler")
      return (
        <Image src="traveler.png" alt="traveler" style={{ width: "100%" }} />
      )
  }
  return (
    <>
      <div className="sign-in-form-container">
        <div
          style={{
            maxWidth: "100px",
            margin: "20px auto",
            marginBottom: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            // if (authContext) navigate("/app/dashboard")
            // else navigate("/")
            navigate("/")
          }}
        >
          <img src={logo} alt="" />
        </div>
        <p
          // className="title is-3 font-lato-700 "
          style={{
            color: "#4a4a4a",
            // color: "#4a4a4a",
            fontSize: "40px",
            letterSpacing: "0.5px",
            // textTransform: "uppercase",
            fontWeight: "600",
            marginBottom: "40px",
            textAlign: "center",
          }}
        >
          Sign up
        </p>
        {/* <hr className="hr-branded"></hr> */}
        <div className="columns is-vcentered">
          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-3-desktop is-4-tablet ">
            <div style={{ position: "relative" }}>
              <div className="center" style={{ zIndex: "99" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    textTransform: "uppercase",
                    fontWeight: "800",
                    letterSpacing: "0.5px",
                  }}
                >
                  {/* Available Soon! */}
                </p>
              </div>
              <div style={{ filter: "blur(0px)" }}>
                <div
                  className="traveler-designer-image"
                  onClick={() => {
                    navigate("/signup-as-traveler")
                  }}
                  style={{
                    width: "200px",
                    margin: "0 auto",
                    padding: "15px",
                    cursor: "pointer",
                  }}
                >
                  {displayRightImage("traveler")}
                </div>
                <button
                  className="button is-warning"
                  onClick={() => {
                    navigate("/signup-as-traveler")
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  Sign up as traveler
                </button>
              </div>
            </div>
          </div>
          <div className="column is-2-desktop ">
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              OR
            </p>
          </div>
          <div className="column is-3-desktop is-4-tablet ">
            <div
              className="traveler-designer-image"
              onClick={() => {
                navigate("/signup-as-designer")
                // navigate("/request-designer-invite")
              }}
              style={{
                width: "200px",
                margin: "0 auto",
                padding: "15px",
                cursor: "pointer",
              }}
            >
              {displayRightImage("designer")}
            </div>
            <button
              className="button is-warning"
              onClick={() => {
                navigate("/signup-as-designer")
                // navigate("/request-designer-invite")
              }}
              style={{
                width: "100%",
              }}
            >
              Sign up as a designer
              {/* Request an invite */}
            </button>
          </div>

          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-3-desktop is-8-tablet">
            <p style={{ margin: "26px 0px", float: "right" }}>
              Already have an account?{" "}
              <a className="link" href="/signin">
                Sign In
              </a>
            </p>
          </div>
          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        </div>

        <div className="landscape">{/* <img src={landscape} alt="" /> */}</div>
      </div>
    </>
  )
}
