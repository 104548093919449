import React, { Fragment } from "react"
import Layout from "../components/layout"
import SignUpForm from "../components/sign-up-form/SignUpForm"
import { SignUpPicker } from "../components/sign-up-form/SignupPicker"
import LayoutSimple from "../components/layoutSimple"
import SEO from "../components/seo"

const SignUnPage = () => {
  React.useEffect(() => {
    document.title = "Sign up | voyayge"
  }, [])

  return (
    <Fragment>
      {/* <SignUpForm type={"traveler"} /> */}
      <SignUpPicker />
    </Fragment>
  )
}

export default () => (
  <LayoutSimple>
    <SEO
      pageTitle="Sign Up"
      description="Sign Up as a traveler or a travel designer on voyayge"
    />
    <SignUnPage />
  </LayoutSimple>
)
